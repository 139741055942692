<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <p class="font-weight-light">Commands</p>
        <div>
          <v-btn @click="sessionSetRole()">Session Set Role</v-btn>
        </div>
        <div>
          <v-btn @click="diskStats()">Disk Stats</v-btn>
        </div>
        <div>
          <v-btn @click="timeSet()">Set Time</v-btn>
        </div>
        <div>
          <v-btn @click="eventsList()">Events List</v-btn>
        </div>
        <div>
          <v-btn @click="now()">Now</v-btn>
        </div>
        <div>
          <v-btn @click="otpOpen()">Open OTP</v-btn>
        </div>
        <div>
          <v-btn @click="codeOpen()">Open Code</v-btn>
        </div>
        <div>
          <v-btn @click="codeCreate()">Create Code</v-btn>
        </div>
        <div>
          <v-text-field
            label="Lock Code"
            single-line
            v-model="code"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            label="Event Position"
            single-line
            v-model="event_position"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BLE from "../lib/ble";
import CMD from "../lib/cmd";
let comm = undefined;

export default {
  props: {
    source: String,
  },
  created() {},
  mounted() {
    console.log("setting up comms for device: ", this.$route.params.id);

    comm = new Communication(this.$route.params.id);
    comm.sampleCallback = this.sampleCallback.bind(this);
    comm.disconnectCallback = this.disconnected.bind(this);

    comm.connect(() => {
      this.msg = "Connected";
      this.checking = true;
    });
  },
  methods: {
    sessionSetRole() {
      comm.write(CMD.CMD_SESSION_SET_ROLE);
    },
    diskStats() {
      comm.write(CMD.CMD_DISK_STATS);
    },
    timeSet() {
      let d = new Date();
      console.log(CMD.CMD_TIME_SET, Math.floor(d.getTime()/1000));
      comm.write(CMD.CMD_TIME_SET + d.getTime());
    },
    eventsList() {
      for(let i = this.event_position; i < this.event_position + 5; i++){
        comm.write(CMD.CMD_EVENTS_LIST+i);
      }
    },
    now() {
      comm.write(CMD.CMD_TIME_NOW);
    },
    otpOpen() {
      comm.write(CMD.CMD_OTP_OPEN);
    },
    codeOpen() {
      comm.write(CMD.CMD_CODE_OPEN+this.code);
    },
    codeCreate() {
      let cmd = CMD.CMD_CODE_CREATE + '|0|'+this.code+'|0'
      console.log(cmd)
      comm.write(cmd);
    },
    read() {
      comm.read();
    },
    async sampleCallback(data) {
      console.log(data);
    },
    disconnected() {
      this.$router.push({ name: "Scan" });
    },
  },
  destroyed() {
    comm.disconnect();
  },
  data: () => ({
    code: "1221",
    event_position: 1,
    msg: "Connecting",
    checking: false,
  }),
};

class Communication {
  svcUUID = "6e400001-b5a3-f393-e0a9-e50e24dcca9e";
  writeUUID = "6e400002-b5a3-f393-e0a9-e50e24dcca9e";
  notifyUUID = "6e400003-b5a3-f393-e0a9-e50e24dcca9e";

  id = undefined;
  device = undefined;
  ble = undefined;
  sampleCallback = undefined;
  disconnectCallback = undefined;

  constructor(id) {
    this.id = id;
    this.write = this.write.bind(this);
    this.notify = this.notify.bind(this);
  }

  connect(callback) {
    this.disconnectCallback = this.disconnectCallback.bind(this);
    this.ble = new BLE();
    this.ble.connect(
      this.id,
      (device) => {
        console.log(device);
        console.log(this);
        this.device = device;
        this.ble.startNotification(
          this.id,
          this.svcUUID,
          this.notifyUUID,
          this.notify,
          console.log
        );
        callback();
      },
      this.disconnectCallback
    );
  }

  notify(data) {
    data = this._parse(data);
    if (this.sampleCallback) {
      this.sampleCallback(data);
    }
    console.log(data);
  }

  disconnect() {
    if (this.device) {
      this.ble.disconnect(this.id);
    }
  }

  write(data) {
    let enc = new TextEncoder();
    return this.ble.write(
      this.id,
      this.svcUUID,
      this.writeUUID,
      enc.encode(data)
    );
  }

  _parse(data) {
    data = String.fromCharCode.apply(null, new Uint8Array(data));
    return data;
  }

  disconnected() {
    this.disconnectCallback();
  }
}
</script>